<template>
  <div v-loading="loading">
    <div class="page-title">
      <h1>
        Batches
      </h1>
    </div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/batches`"
      :fields="fields"
      title="Batches"
      item-type="batches"
      :filters="filters"
      :show-title="false"
      default-sort-direction="descending"
      default-sort-prop="created_at"
      saved-table-sort-name="batch-index"
      v-if="$store.getters['auth/hasPermission']('batch.read') && ready"
      :default-filter-data="df"
      cache-table-filters
      :allow-selection="getMassUpdate()"
      ref="table"
      :selected-rows.sync="batch_selection"
    >

      <template v-slot:header-right>
        <el-button size="medium" type="primary" @click="showBatchCreate = true" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('batch.create')">Create</el-button>
        <div class="ml-2 inline-block" v-if="selected_empty_batches.length">
          <el-button type="primary" size="medium" @click="startEmptyBatches">
            Rerun batches
          </el-button>
        </div>
      </template>

      <template v-slot:description-slot="props">
        {{props.row.description | shortenText(100)}}
      </template>

      <template v-slot:id="props">
        <a :href="`/company/${$store.state.company.company.id}/batch/${props.row.id}`" class="font-bold">
          {{props.row.id}}
        </a>
      </template>

      <template v-slot:campaign-slot="props">
        <a :href="`/company/${$store.state.company.company.id}/campaigns/${props.row.campaign_id}`" class="font-bold">
          {{props.row.campaign.name}}
        </a>
      </template>

      <template v-slot:bulk-email-states-slot="props">
        {{ props.row.email_state.total }}
        <span v-if="props.row.email_state.awaiting"> | <a href="#" class="text-blue-600 font-medium" @click="openBulkForm(props.row)">
          {{ props.row.email_state.awaiting }}
        </a>
        </span>
      </template>

      <template v-slot:owner-slot="props">
        <div v-if="props.row.owner">
          <div>
            {{ `${props.row.owner.firstname} ${props.row.owner.lastname}` }}
          </div>
        </div>
        <div v-else>
          N/A
        </div>
      </template>

      <template v-slot:processing-slot="props">
        <div v-if="props.row.job_completed_on && props.row.created_at">
          <div>
            {{  props.row.created_at | moment("from", props.row.job_completed_on, true) }}
          </div>
        </div>
        <div v-else>
          N/A
        </div>
      </template>

      <template v-slot:status-slot="props">
        <div v-if="props.row.reviewed_status && props.row.reviewed_status.pending_review && props.row.reviewed_status.pending_review > 0">
          <div>
            {{ `${props.row.reviewed_status.reviewed.toLocaleString()} / ${props.row.reviewed_status.total.toLocaleString()} reviewed.` }}
          </div>
          <el-progress :show-text="false" :percentage="(props.row.reviewed_status.reviewed / props.row.reviewed_status.total) * 100"></el-progress>
        </div>
        <div v-else>
          {{ getNiceStatus(props.row) }}
        </div>
      </template>

      <template v-slot:site-slot="props">
        <a :href="`/clients/${props.row.site.client_id}/sites/${props.row.site.id}`" v-if="props.row.site">
          <div class="flex flex-row items-center">
            <div class="mr-2">
              <logo-widget v-model="props.row.site.base_url" :size="16" />
            </div>
            {{props.row.site.domain}}
          </div>
        </a>
      </template>

      <template v-slot:job|completed_on="props">
        <div v-if="props.row.status==='processing'">
          {{ getNiceStatus(props.row) }}
        </div>
        <div v-else>
          {{ props.row.job_completed_on | momentLocal("ddd MMMM DD YYYY") }}<br/>
          <span class="text-xs">
                {{  props.row.job_completed_on | momentLocal("hh:mm A Z") }}
              </span>
        </div>
      </template>

      <template v-slot:job|next_run="props">
        {{ props.row.job_next_run | momentLocal("ddd MMMM DD YYYY") }}<br/>
        <span class="text-xs">
                {{  props.row.job_next_run | momentLocal("hh:mm A Z") }}
              </span>
      </template>

      <template v-slot:actions-slot="props">
        <a
          :href="`/company/${$store.state.company.company.id}/batch/${props.row.id}`"
          v-if="$store.getters['auth/hasPermission']('batch.read')"
          >
        <el-button
          size="small"
          icon="el-icon-view"
          type="primary">
          View
        </el-button>
        </a>
      </template>
      <template v-slot:id-slot="props">
        <a
          :href="`/company/${$store.state.company.company.id}/batch/${props.row.id}`" class="font-bold">
          {{ props.row.id }}
        </a>
      </template>
    </trieste-table>
    <batch-quick-create :show.sync="showBatchCreate" v-if="$store.getters['auth/hasPermission']('batch.create')" />
    <initial-bulk-emailing :bulk_link="bulk_button.link" :site_id="bulk_button.site_id" ref="bulkStatusButton" />
  </div>
</template>

<script>
import LogoWidget from "@/components/incs/LogoWidget";
import BatchQuickCreate from "@/components/views/batch/QuickCreate";
import InitialBulkEmailing from '@/components/views/batch/InitialBulkEmailing';
import moment from 'moment/moment';

export default {
  name: 'AllCompanyBatches',
  components: {InitialBulkEmailing, BatchQuickCreate, LogoWidget },
  metaInfo() {
    return {
      title: () => `${this.$store.state.company.company.name} Batches`
    }
  },
  data() {
    const df = {
      Owner: [this.$store.state.auth.user.id],
    }
    return {
      df,
      batch_selection: [],
      bulk_button: {
        link: null,
        site_id: null
      },
      loading: false,
      status: {
        options: [
          {value: 'all', label: 'All'},
          {value: 'active', label: 'Active'},
          {value: 'suspended', label: 'Suspended'}
        ],
        value: 'active'
      },
      fields: [
        {
          title: 'id',
          name: 'id-slot',
          sortField: 'id',
        },
        {
          title: 'V1 ID',
          name: 'legacy_id',
          sortField: 'legacy_id',
          formatter: (val) => !val ? '-' : ( val + '' )
        },
        {
          title: 'Name',
          name: 'name',
          sortField: 'name',
        },
        {
          title: 'Count',
          name: 'bulk-email-states-slot'
        },
        {
          title: 'Owner',
          name: 'owner-slot',
          fieldName: 'owner',
        },
        {
          title: 'status',
          name: 'status-slot',
          sortField: 'status',
        },
        {
          title: 'Site',
          name: 'site-slot',
          fieldName: 'site',
        },
        {
          title: 'Campaign',
          name: 'campaign-slot',
          fieldName: 'campaign',
        },
        {
          title: 'Type',
          name: 'batch_type',
          sortField: 'batch_type',
          formatter: val => val.replace(/_/g, ' ')
        },
        {
          title: 'Created at',
          name: 'created_at',
          dateField: true,
          sortField: 'created_at',
          titleClass: 'text-left',
          dataClass: 'text-left',
        },
        {
          title: 'Updated at',
          name: 'updated_at',
          dateField: true,
          sortField: 'updated_at',
          titleClass: 'text-left',
          dataClass: 'text-left',
        },
        {
          title: 'Completed on',
          name: 'job_completed_on',
          dateField: true,
          sortField: 'job_completed_on',
          titleClass: 'text-left',
          dataClass: 'text-left',
        },
        {
          title: 'Processing time',
          name: 'processing-slot',
          titleClass: 'text-left',
          dataClass: 'text-left',
        },
        {
          title: 'Success %',
          name: 'success_ratio',
          sortField: 'success_ratio',
          numberField: true,
          titleClass: 'text-left',
          dataClass: 'text-left',
          formatter: (val) => val + '%'
        },
        {
          title: '',
          name: 'actions-slot',
          width: '110px',
          fixed: 'right'
        },
      ],
      filters: [
        {
          label: 'ID',
          name: 'id'
        },
        {
          label: 'V1 ID',
          name: 'legacy_id'
        },
        {
          label: 'Name'
        },
        {
          label: 'description'
        },
        {
          label: 'status',
          type: 'select',
          multiple: true,
          options: ['reviewed', 'completed', 'empty', 'error', 'rerun', 'processing']
        },
        {
          label: 'Site',
          type: 'site'
        },
        {
          label: 'Site Target',
          type: 'site-target',
          name: 'site_target',
          multiple: true
        },
        {
          label: 'Type',
          type: 'select',
          multiple: true,
          options: [
            { label: 'Google serps', value: 'google_simple_query_v2' },
            { label: 'Content Analysis', value: 'content_analysis' },
            { label: 'Bing serps', value: 'bing_search_api' },
            { label: 'Baidu Serp query', value: 'baidu_search_api' },
            { label: 'Existing backlinks', value: 'existing' },
            { label: 'Domain backlinks', value: 'domain' },
            { label: 'Page backlinks', value: 'single_page' },
            { label: 'External links on a page', value: 'page_url' },
            { label: 'Custom URL list', value: 'html_data' }
          ]
        },
        {
          type: 'user',
          multiple: true,
          label: 'Owner',
          name: 'owner_id'
        },
        {
          label: 'Created at',
          name: 'created_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Updated at',
          name: 'updated_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Completed on',
          name: 'job_completed_on',
          type: 'date',
          dateType: 'daterange'
        },
      ],
      showBatchCreate: false,
      defaultFilters: {},
      ready: false
    }
  },
  mounted() {
    if(!this.$store.getters['auth/hasPermission']('batch.read')) {
      this.$message.error('You do not have permissions to view batches.');
      this.$router.push('/');
    } else {
      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        this.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: company.name
          },
          {
            label: 'Batches'
          }
        ])
      })
      this.ready = true
    }
  },
  computed: {
    selected_empty_batches() {
      if(this.$store.getters['auth/hasPermission']('admin')) return this.batch_selection;
      return this.batch_selection.filter(b => {
        return ['empty', 'error'].includes(b.status) || ( ['processing', 'processing_rld'].includes(b.status) && moment(b.created_at).unix() < moment().subtract(4, 'hours').unix() )
      })
    }
  },
  methods: {
    getNiceStatus(row) {
      if(row.status === 'processing') {
        return `processing (${row.job_percentage ? row.job_percentage : 0 }% complete)`
      } else if (row.status === 'error') {
        return `Failed (${row.error_message})`
      }
      return row.status
    },
    deleteBatch(batch) {
      this.$confirm(`Are you sure you want to delete '${batch.name}'? This will delete ALL site links`, 'warning')
        .then(() => {
          //console.log('hello')
          this.$http.delete(`/v1/companies/${this.$store.state.company.company.id}/campaigns/${this.$route.params.campaignId}/batches/${batch.id}`)
            .then(() => {
              this.$message.success('Successfully deleted the batch');
              this.refresh()
            })
            .catch(() => {
              this.$message.error('An error occurred deleting that batch. Please try again later.')
            })
        })
        .catch((e) => { console.error(e)/* do nothing */})
    },
    openBulkForm(item) {
      if(this.bulk_button.site_id !== item.site_id) {
        this.$refs.bulkStatusButton.reloadResources()
      }
      this.bulk_button.site_id = item.site_id
      const linkId = item.email_state.next || null
      this.bulk_button.link = linkId ? { id: linkId } : null
      this.$refs.bulkStatusButton.toggleBulkEmailingForm()
    },
    getMassUpdate() {
      const allowedRules = [
        'admin',
        'link_manager',
        'Linkdev Manager'
      ]
      return !!this.$store.state.auth.user.roles.find(r => allowedRules.includes(r.toLowerCase()))
    },
    startEmptyBatches() {
      const batches = this.selected_empty_batches;
      if(batches.length) {
        this.loading = true;
        this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/batches/start`, {
          batch_ids: batches.map(b => b.id)
        }).then(() => {
          this.$message.success('Successfully restarted the batches.')
          this.$refs.table.getData()
        })
          .catch(() => this.$message.error('The was an error restarting the batches.') )
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
