<template>
  <div v-loading="loading">
    <div class="pb-4 flex flex-row justify-end w-full">
      <el-select v-model="status" @change="getSites">
        <el-option value="all" label="All" />
        <el-option value="active" label="Active" />
        <el-option value="suspended" label="Suspended" />
      </el-select>
    </div>
    <div v-if="loading" v-loading="true">

    </div>
    <div v-else>
      <site-requirements v-for="(site, i) in sites" :key="i"
                         :set-site="site" :label="getRowName(site)" :collapsable="false" render-urls />
    </div>
  </div>
</template>
<script>
  import SiteRequirements from "@/components/views/links/SiteRequirements";
  export default {
    components: {SiteRequirements},
    data() {
      return {
        sites: [],
        loading: false,
        include_suspended: true,
        status: 'all'
      }
    },
    props: {
      customNameFunction: {
        type: Function,
        default: null
      },
      all: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.getSites()
    },
    methods: {
      getSites() {
        const params = {
          all: this.all,
          status: this.status
        }
        if(this.$route.params.clientId) {
          params.client_id = this.$route.params.clientId
        }
        this.loading = true
        this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/sites`, {
          params
        }).then((res) => {
          this.sites = res.data.data
        }).catch(() => {
          this.$message.error('Failed to load the sites.')
        }).finally(() => {
          this.loading = false
        })
      },
      getRowName(item) {
        if(typeof this.customNameFunction === 'function') {
          return this.customNameFunction(item)
        }

        return `${item.domain} - ${item.status}`
      }
    }
  }
</script>
